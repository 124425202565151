import React, { useState } from 'react';
import { Formik } from 'formik';
import axios from "axios";
import {message} from "antd";

const RegisterForm = ({handleCancel}) => {
  const [registerData, setRegisterData] = useState({
    name: '',
    email: '',
    clinicName: '',
    address: '',
    phoneNumber: '',
  });

  console.log(registerData,"registerData")
  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          name: '',
          clinicName: '',
          address: '',
          phoneNumber: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Please fill email';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }

          if (!values.name) {
            errors.name = 'Please fill name';
          }

          if (!values.clinicName) {
            errors.clinicName = 'Please fill clinic name';
          }

          if (!values.address) {
            errors.address = 'Please fill address';
          }

          if (!values.phoneNumber) {
            errors.phoneNumber = 'Please fill phone number';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
            try {
              // Make a POST request using Axios
              const response = await axios.post(
                `http://103.99.149.26:3101/clinical/register`,
                {
                  address: values.address,
                  clinic_name: values.clinicName,
                  name: values.name,
                  email: values.email,
                  number: values.phoneNumber, // Fixed the property name
                  status_of_visit: 0
                }
              );
              message.success(response.data.message); // Log the response
              // Handle success (you might want to update the UI or show a success message)
            } catch (error) {
                message.error(error.response.data.message); // Log the response
              // Handle error (you might want to show an error message to the user)
            } finally {
              setSubmitting(false); // Make sure to set submitting to false after the request
            }
          }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className='container-register'>
            <div className='container-register-div-input'>
              <label>Email:</label>
              <div>
              <input
                type="email"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  setRegisterData((prevData) => ({
                    ...prevData,
                    email: e.target.value,
                  }));
                }}
                onBlur={handleBlur}
                value={values.email}
                className={errors.email && touched.email ? 'error modal-input-register' : 'modal-input-register'}
              />
              
              {errors.email && touched.email && (
                <>
                <div className="error-message">{errors.email}</div>
                </>
              )}
             </div>
            </div>

            <div className='container-register-div-input'>
              <label>Name:</label>
              <div>
              <input
                type="text"
                name="name"
                onChange={(e) => {
                  handleChange(e);
                  setRegisterData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }));
                }}
                onBlur={handleBlur}
                value={values.name}
                className={errors.name && touched.name ? 'error modal-input-register' : 'modal-input-register'}
              />
              {errors.name && touched.name && (
                <div className="error-message">{errors.name}</div>
              )}
              </div>
            </div>

            <div className='container-register-div-input'>
              <label>Clinic Name:</label>
              <div>
              <input
                type="text"
                name="clinicName"
                onChange={(e) => {
                  handleChange(e);
                  setRegisterData((prevData) => ({
                    ...prevData,
                    clinicName: e.target.value,
                  }));
                }}
                onBlur={handleBlur}
                value={values.clinicName}
                className={errors.clinicName && touched.clinicName ? 'error modal-input-register' : 'modal-input-register'}
              />
              {errors.clinicName && touched.clinicName && (
                <div className="error-message">{errors.clinicName}</div>
              )}
              </div>
            </div>

            <div className='container-register-div-input'>
              <label>Address:</label>
              <div>
              <input
                type="text"
                name="address"
                onChange={(e) => {
                  handleChange(e);
                  setRegisterData((prevData) => ({
                    ...prevData,
                    address: e.target.value,
                  }));
                }}
                onBlur={handleBlur}
                value={values.address}
                className={errors.address && touched.address ? 'error modal-input-register' : 'modal-input-register'}
              />
              {errors.address && touched.address && (
                <div className="error-message">{errors.address}</div>
              )}
              </div>
            </div>

            <div className='container-register-div-input'>
              <label>Phone Number:</label>
              <div>
              <input
                type="tel"
                name="phoneNumber"
                onChange={(e) => {
                  handleChange(e);
                  setRegisterData((prevData) => ({
                    ...prevData,
                    phoneNumber: e.target.value,
                  }));
                }}
                onBlur={handleBlur}
                value={values.phoneNumber}
                className={errors.phoneNumber && touched.phoneNumber ? 'error modal-input-register' : 'modal-input-register'}
              />
              {errors.phoneNumber && touched.phoneNumber && (
                <div className="error-message">{errors.phoneNumber}</div>
              )}
              </div>
            </div>
            
            <button type="submit" className='cancel-modal-btn' onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit" className='submit-modal-btn' disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterForm;
