import React from 'react'
import AboutUsImg from "../../assets/digiCareXAboutus.png"

const AboutUs = () => {
  return (
    <div id="aboutus" className='d-flex justify-content-center'>
        <img src={AboutUsImg} alt="" />
    </div>
  )
}

export default AboutUs;