import React from 'react'
import "../homePage/home.css";
import Button from "react-bootstrap/Button";
import ServiceImg from "../../assets/servicesPng.png"
import ServiceImg2 from "../../assets/servicesPng-2.png"
import ServiceImg3 from "../../assets/servicesPng-3.png"
import ServiceImg4 from "../../assets/appstore.png"
import ServiceImg5 from "../../assets/scanner.png"
import ServiceImg6 from "../../assets/doctorservices.png"
import Subscription from "../../assets/subscriptions.png"


const Services = () => {
  return (
    <div id="services" className="d-flex flex-column align-items-center justify-content-center">

        <div className="mt-2 mx-2 d-flex align-items-center justify-content-center" style={{width:"80%"}}>
          <div>
            <h4 className="hm-title1 mb-2">Patient App  Service</h4>
            <p className="hm-paras mt-4">
            Access personalized healthcare with our DigiCareX,
            connecting you to virtual consultations with experienced professionals.
            Manage appointments, prescriptions,and records seamlessly on
            our user-friendly platform. Receive real-time health reminders and 
             educational resourcesfor proactive wellness. Modernize your healthcare
             experience with our secure and confidential app services. Your well-being, our priority.
            </p>
            <Button variant="primary" className="hm-btn3">
              Download App
            </Button>
          </div>
          <div>
          <img src={ServiceImg} alt="" style={{objectFit:"cover"}} />
          </div>
        </div>

        {/* Dentist App service */}

        <div className="mt-2 mx-2 d-flex align-items-center justify-content-center" style={{width:"80%",background:"#BED7FF"}}>
          
        <div>
          <img src={ServiceImg2} alt="" style={{objectFit:"cover"}} />
          </div>
          
          <div>
            <h4 className="hm-title1 mb-2">DENTIST APP SERVICE</h4>
            <p style={{padding:"0px 20px"}} className="hm-paras mt-4">
            Transform your dental care experience with our specialized Dentist App Service. Schedule appointments with ease, ensuring timely and convenient access to oral healthcare professionals. Access virtual consultations for expert advice and guidance on dental concerns. Seamlessly manage your dental records and treatment plans in one secure platform. Prioritize your oral health with our user-friendly app, where personalized dental care meets modern convenience.
            </p>
            <Button variant="primary" className="hm-btn3">
              Download App
            </Button>
          </div>
        
        </div>
    
    {/* Clinical APP Service */}
    <div className="mt-4 mx-2 d-flex align-items-center justify-content-center" style={{width:"80%"}}>
          <div>
            <h4 className="hm-title1 mb-2">Clinical APP Service</h4>
            <p className="hm-paras mt-4">
            Elevate your healthcare journey with our Clinical App Service, a comprehensive platform connecting you to a spectrum of medical services. Schedule appointments, access virtual consultations, and seamlessly manage your health records with ease. Empower yourself with real-time health updates, reminders, and educational resources for proactive well-being. Our user-friendly interface ensures a smooth and secure experience, putting your health information at your fingertips. Experience a modern approach to clinical care that prioritizes convenience, accessibility, and personalized attention.</p>
            <Button variant="primary" className="hm-btn3">
              Download App
            </Button>
          </div>
          <div>
          <img src={ServiceImg3} alt="" style={{objectFit:"cover"}} />
          </div>
        </div>
        {/* Digital HEALTH AND DENTAL  CARE  */}
        <div className="mt-4 mx-2 d-flex flex-column align-items-center" style={{width:"80%"}}>
            <h4 className="hm-title1 mb-2">Digital HEALTH AND DENTAL  CARE </h4>
            <p className="hm-paras mt-4">
            Experience a revolution in healthcare with our Digital Health and Dental Care services—seamless, personalized, and designed to prioritize your well-being. From virtual consultations to streamlined dental appointments, we bring modern convenience to the forefront of your health journey.
            Revolutionize your well-being with our integrated Digital Health and Dental Care services, providing personalized and accessible care in one streamlined platform.Personalized, Streamlined, Accessible.</p>

          <div className='d-flex justify-content-center'>
           
                <img src={ServiceImg4} alt="" style={{width:"25%",objectFit:"contain"}}/>
                <img src={ServiceImg5} alt="" style={{width:"25%",objectFit:"contain"}}/>
                <img src={ServiceImg6} alt="" style={{width:"25%",objectFit:"contain"}}/>
          </div>
          </div>
          {/* subscriptions */}
          <h4 className="hm-title1 mb-2 mt-4">Monthly subscriptions</h4>
          <img src={Subscription} alt="" />
    </div>
  )
}

export default Services;