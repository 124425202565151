import React from "react";
import Header from "../layouts/header";
import DigiLogo from "../../assets/digicare-logo2.png";
import Button from "react-bootstrap/Button";
import "./home.css";
import Calendar from "../../assets/mobileCalendar.png";

const Home = () => {
  return (
    <div id="home">
      <Header />
      <div className="mt-5 mx-3 ">
        <div
          className="d-flex align-items-center justify-content-flex-start"
          style={{ marginLeft: "7%" }}
        >
          <img src={DigiLogo} alt="" style={{ height: "35px" }} />
          <div>
            <Button variant="primary" className="hm-btn">
              Doctor Login
            </Button>
            <Button variant="primary" className="hm-btn2">
              Subscription
            </Button>
          </div>
        </div>
        <div className="mt-2 mx-2 d-flex align-items-center justify-content-evenly">
          <div>
            <h4 className="hm-title1">Digital Dental and Health Care</h4>
            <p className="hm-paras">
              The Digital Dental Care application integrates cutting-edge <br />
              technology to simplify appointment scheduling, streamline patient{" "}
              <br />
              records, and enhance communication between dentists and patients.{" "}
            </p>
            <Button variant="primary" className="hm-btn3">
              Download App
            </Button>
          </div>
          <div>
            <img src={Calendar} alt="" style={{ height: "330px" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
