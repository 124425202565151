import React, { useState } from 'react';
import { Modal } from 'antd';
import "./layout.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/digilogo.png";
import Button from "react-bootstrap/Button";
import RegisterForm from './RegisterForm';

export default function Header() {
  const [open, setOpen] = useState(false);
  const handlePopForRegister = () => {
    setOpen(true);
  };
  const handleSubmitRegister = () => {
      setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
 
  return (
    <Navbar className="lay-head" expand="lg" variant="dark">
      <Container fluid className="px-4">
        <Navbar.Brand href="#home">
          <img src={Logo} alt="" className="brandLogo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="navbars">
            <Nav.Link href="#home" className="nav-name">
              Home
            </Nav.Link>
            <Nav.Link href="#aboutus" className="nav-name">
              Aboutus
            </Nav.Link>
            <Nav.Link href="#services" className="nav-name">
              Services
            </Nav.Link>
            <Nav.Link href="#contacts" className="nav-name">
              Contact
            </Nav.Link>
          </Nav>
          <Nav>
            <Button variant="primary" className="custom-btn-color" onClick={handlePopForRegister}>
              Register
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Modal
        open={open}
        title="DigiCareX-Register Form"
        onOk={handleSubmitRegister}
        onCancel={handleCancel}
        // footer={[
        //   <button className='cancel-modal-btn' key="back" onClick={handleCancel}>
        //     Cancel
        //   </button>,
        //   <button className="submit-modal-btn" key="submit" type="primary" onClick={handleSubmitRegister}>
        //     Submit
        //   </button> 
        // ]}
      >
       <RegisterForm handleCancel={handleCancel}/>
      </Modal>
    </Navbar>
  );
}
