// import { Route, Routes } from "react-router";
import Home from "./components/homePage/home";
import AboutUs from "./components/aboutUs/aboutus";
import Services from "./components/services/services"

function App() {
  return (
    <div>
      <Home/>
      <AboutUs/>
      <Services/>
      {/* <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes> */}
    </div>
  );
}
export default App;
